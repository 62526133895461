<div fxLayout="row wrap">
  <div fxFlex="20">
    <p *ngIf="selection.selected.length">
      {{ selection.selected.length }}
      {{ selection.selected.length == 1 ? "user" : "users" }} are selected
    </p>
  </div>
  <div fxFlex="100" style="display: flex; justify-content: space-between">
    <div fxFlex="50">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          <mat-label>Search user by name, email or role</mat-label>
          <input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchUser" name="searchUser" />
          <button *ngIf="searchUser" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
            Search
          </button>
        </div>
      </form>
    </div>
    <div>
      <button mat-raised-button color="primary" *ngIf="!isRoleLearner" style="margin-right: 10px" (click)="addGroup()"
        [disabled]="!selection.selected.length || loading">
        Assign to group
      </button>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menuUserAdd"
        [disabled]="!addUserButton || loading">
        <mat-icon>person_add_alt_1</mat-icon> Add User
      </button>
    </div>
  </div>
  <mat-menu #menuUserAdd="matMenu">
    <button mat-menu-item (click)="addSingleUser = !addSingleUser; addUserButton = !addUserButton;">
      Add User
    </button>
    <button mat-menu-item (click)="fileUploadStep = 1; addUserButton = !addUserButton">
      Upload Users
    </button>
  </mat-menu>
  <mat-card *ngIf="addSingleUser">
    <mat-card-content>
      <div fxLayout="row wrap">
        <div fxFlex="80">
          <h4>Add User</h4>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button (click)="closeSingleUserForm()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div fxFlex="40" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>Email Address*</mat-label>
            <input matInput [formControl]="formSingleUser.controls['email']" />
            <button mat-icon-button matSuffix (click)="welcomeEmail = !welcomeEmail">
              <mat-icon>{{
                welcomeEmail ? "send" : "cancel_schedule_send"
                }}</mat-icon>
            </button>
          </mat-form-field>
          <small *ngIf="
              formSingleUser.controls['email'].hasError('pattern') &&
              formSingleUser.controls['email'].value.length > 0
            " class="text-danger support-text">Please enter a valid email address</small>
        </div>


        <div fxFlex="30" style="padding-left: 10px">
          <app-autocomplete [options]="companyGroup" (onFilter)="changeHandler($event)" placeholder="Select Group"
            optionKey="group_name" [required]="false" (onSelected)="selectedGroup($event)"></app-autocomplete>
        </div>
        <div fxFlex="30" style="padding-left: 10px">
          <mat-form-field>
            <mat-label>Role*</mat-label>
            <mat-select [formControl]="formSingleUser.controls['role']">
              <mat-option *ngFor="let role of availableRoles" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="40" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>Name*</mat-label>
            <input matInput [formControl]="formSingleUser.controls['name']" />
          </mat-form-field>
          <!-- <small
            *ngIf="formSingleUser.controls['name'].value.length > 0"
            class="text-danger support-text">Please enter name</small> -->
        </div>
        <div fxFlex="30" style="padding-left: 10px">
          <mat-form-field>
            <mat-label>License Bundles</mat-label>
            <mat-select [formControl]="formSingleUser.controls['license_bundles']"
              *ngIf="licenseBundles && licenseBundles.length > 0" multiple>
              <mat-option *ngFor="let lic of licenseBundles" [value]="lic.id">
                {{ lic.name }}
              </mat-option>
            </mat-select>

            <mat-select *ngIf="!licenseBundles || licenseBundles.length === 0">
              <mat-option>No License Bundle Found</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="30" style="padding-left: 10px">
          <mat-form-field>
            <mat-label>Licenses</mat-label>
            <mat-select [formControl]="formSingleUser.controls['licenses']" multiple
              *ngIf="activeLicenses && activeLicenses.length > 0">
              <mat-option *ngFor="let lic of activeLicenses" [value]="lic.id">
                <!-- {{ lic.module }} -->
                <div class="d-flex align-items-center lic-card">
                  <img class="mat-card-avatar" [ngClass]="small" [src]="lic.module_icon" />
                  <div>
                    <div class="lic-card__name">
                      {{ lic.name }}
                    </div>
                    <div class="lic-card__description">
                      Expires: {{ lic.expiry }}
                    </div>
                  </div>
                </div>
              </mat-option>
            </mat-select>

            <mat-select *ngIf="!activeLicenses || activeLicenses.length === 0">
              <mat-option>No Application Found</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <div fxFlex="30" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>VR Headset</mat-label>
            <mat-select [formControl]="formSingleUser.controls['headset']">
              <mat-option *ngFor="let h of headsetsPurchased" [value]="h.id">
                {{ h.headset_name.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="70" style="padding-left: 10px">
          <mat-form-field>
            <mat-label>Address 1</mat-label>
            <input matInput [formControl]="formSingleUser.controls['address_1']" />
          </mat-form-field>
        </div>
        <div fxFlex="50" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>Address 2</mat-label>
            <input matInput [formControl]="formSingleUser.controls['address_2']" />
          </mat-form-field>
        </div>
        <div fxFlex="50" style="padding-left: 10px">
          <mat-form-field>
            <mat-label>Address 3</mat-label>
            <input matInput [formControl]="formSingleUser.controls['address_3']" />
          </mat-form-field>
        </div>
        <div fxFlex="40" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>Address 4</mat-label>
            <input matInput [formControl]="formSingleUser.controls['address_4']" />
          </mat-form-field>
        </div>
        <div fxFlex="40" style="padding-left: 10px; padding-right: 10px">
          <mat-form-field>
            <mat-label>Postcode</mat-label>
            <input matInput [formControl]="formSingleUser.controls['postcode']" />
          </mat-form-field>
        </div>
        <div fxFlex="20" style="padding-right: 10px">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <input matInput [formControl]="formSingleUser.controls['country']" />
          </mat-form-field>
        </div>
        <div fxFlex="100" style="text-align: center">
          <button mat-raised-button color="primary" (click)="submitSingleUser()" [disabled]="
              formSingleUser.controls['email'].hasError('pattern') ||
              formSingleUser.controls['email'].hasError('required') ||
              formSingleUser.controls['name'].hasError('required') ||
              formSingleUser.controls['role'].hasError('required')
            ">
            <mat-icon>person_add_alt_1</mat-icon> Add User
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <input type="file" accept=".csv" (change)="onFileSelected($event)" #fileUpload style="display: none" />
  <mat-card *ngIf="fileUploadStep > 0">
    <mat-card-content>
      <div fxLayout="row wrap" *ngIf="fileUploadStep === 1">
        <div fxFlex="80">
          <h4>Upload Users</h4>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button (click)="closeUploadUserForm()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div fxFlex="100">
          <p>
            To add multiple users please download our
            <a href="https://cdn.xrlplatform.com/platform-shared/sample_xelerate_user_upload.csv" target="_blank">sample
              csv</a>
            file and add your users credentials to them.
          </p>
          <ul>
            <li>
              In the email field please add the user's email address (required)
            </li>
            <li>In the name field please add the user's full name</li>
            <li>
              Please select a role from the table below and add the ID to the
              role field (required)
            </li>
            <li>
              Please select the relevant licenses from below and add the ID(s)
              as a list separated by ;
            </li>
          </ul>

          <h5>Roles</h5>
          <p>
            Use the table below to complete the rol field by adding the relevant
            ID to the CSV.
          </p>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceRole" class="table employee-list no-wrap">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID for CSV</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsRole"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsRole"></tr>
            </table>
          </div>

          <h5>Licenses</h5>
          <p>
            Use the table below to complete the license field by adding the
            relevant IDs in a list in the CSV. For multiple licenses separate
            the licenses by ; - for example if the IDs were 1 and 2 add 1;2 to
            the CSV.
          </p>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceLicense" class="table employee-list no-wrap">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>License</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.module.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID for CSV</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsLicense"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsLicense"></tr>
            </table>
          </div>
          <h5>License Bundles</h5>
          <p>
            Use the table below to complete the license bundles field by adding
            the relevant IDs in a list in the CSV. For multiple license bundles
            separate the licenses by ; - for example if the IDs were 1 and 2 add
            1;2 to the CSV. Default licenses bundles will be issued without
            addiditon to the CSV
          </p>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceLicenseBundles" class="table employee-list no-wrap">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>License Bundle</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID for CSV</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsLicenseBundles"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsLicenseBundles"></tr>
            </table>
          </div>
          <h5>VR Headsets</h5>
          <p>
            Use the table below to complete the headset field by adding the
            relevant ID to the CSV, leave blank for no headset.
          </p>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceHeadset" class="table employee-list no-wrap">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.headset_name.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID for CSV</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsRole"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsRole"></tr>
            </table>
          </div>
        </div>
        <div fxFlex="100">
          <h5>Upload and Verify</h5>
          <p>
            Click the button below to upload your users, then click verify to
            confirm the CSV file is correct.
          </p>
        </div>

        <div fxFlex="100" style="
            text-align: center;
            width: 100%;
            justify-content: center;
            margin-top: 10px;
          ">

          <div fxFlex="30" style="padding-right: 50px">
            <app-autocomplete [options]="companyGroup" (onFilter)="changeHandler($event)" placeholder="Select Group"
              optionKey="group_name" [required]="false" (onSelected)="selectedGroup($event)"></app-autocomplete>
          </div>
          <button mat-raised-button color="primary" (click)="resetFileUpload()">
            <mat-icon>upload_file</mat-icon> Upload and Verify CSV
          </button>
        </div>
      </div>
      <div fxLayout="row wrap" *ngIf="fileUploadStep === 2">
        <div fxFlex="80">
          <h4>Confirm User Upload</h4>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button (click)="closeUploadUserForm()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div fxFlex="100">
          <p>
            Your CSV file contains {{ newUsersArray.length }} users and the
            system will add {{ successfulNewUsers.length }}. Please check the
            table below and either confirm the users or update the CSV file.
          </p>
        </div>

        <div fxFlex="100">
          <div class="table-responsive" style="overflow: auto; max-height: 600px;">
            <table mat-table [dataSource]="dataSourceNewUsers" class="table employee-list no-wrap">
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'red-color': !element.email_success }">
                  {{ element.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="license">
                <th mat-header-cell *matHeaderCellDef>Licenses</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'red-color': !element.license_success }">
                  <ng-container *ngIf="element.license_success">{{ element.licenses.length }}
                  </ng-container>
                  <ng-container *ngIf="!element.license_success">Error
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'red-color': !element.role_success }">
                  <ng-container *ngIf="element.role_success">{{
                    roleToName(element.role)
                    }}</ng-container>
                  <ng-container *ngIf="!element.role_success">Error</ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="success">
                <th mat-header-cell *matHeaderCellDef>Verified</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="
                      element.license_success &&
                        element.email_success &&
                        element.role_success;
                      else FailedBlock
                    "><i class="fa fa-check-circle" style="color: green"></i></ng-container>
                  <ng-container *ngIf="
                      !element.license_success ||
                        !element.email_success ||
                        !element.role_success;
                      else FailedBlock
                    "><i class="fa fa-times-circle" style="color: red"></i></ng-container>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsNewUsers"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsNewUsers"></tr>
            </table>
          </div>
        </div>
        <div fxFlex="100">
          <p>
            All users without errors will be added if Confirm Users is clicked.
            To correct the errors and upload a new file click Replace CSV.
          </p>
        </div>
        <div fxFlex="50" style="text-align: center; margin-top: 10px">
          <button mat-raised-button color="primary" (click)="submitMultipleUser()"
            [disabled]="!successfulNewUsers.length">
            <mat-icon>person_add_alt_1</mat-icon> Confirm Users
          </button>
        </div>
        <div fxFlex="50" style="text-align: center; margin-top: 10px">
          <button mat-raised-button color="warn" (click)="resetFileUpload()">
            <mat-icon>upload_file</mat-icon> Replace CSV
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div fxFlex="100">
    <mat-progress-bar mode="indeterminate" class="m-t-20" *ngIf="loading"></mat-progress-bar>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="table employee-list no-wrap">

        <ng-container matColumnDef="select" *ngIf="!isRoleLearner" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <app-user-card [userDetail]="element" [optionKey]="'first_name'"></app-user-card>
          </td>
        </ng-container>

        <ng-container matColumnDef="date of joining">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Sign Up Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date_joined | date : "dd/MM/YY HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="last login">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Login</th>
          <td mat-cell *matCellDef="let element">
            {{ element.last_login | date : "dd/MM/YY HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="welcome">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Welcome Email Sent
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.welcome_email_sent | date : "dd/MM/YY HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="licenses">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Licenses</th>
          <td mat-cell *matCellDef="let element">
            <!-- matTooltip="{{ licenseNames(element.active_licenses).join(', ') }}"
            matTooltipClass="my-tooltip" -->
            <ng-container *ngIf="element.active_licenses_count">{{
              element.active_licenses_count
              }}</ng-container><ng-container *ngIf="!element.active_licenses_count">-</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.is_active"><i class="fa fa-check-circle"
                style="color: green"></i></ng-container><ng-container *ngIf="!element.is_active"><i
                class="fa fa-times-circle" style="color: red"></i></ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.user_roles_human">{{
              getRoleName(element.user_roles_human)
              }}</ng-container><ng-container *ngIf="!element.user_roles_human">-</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="headsets">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Headsets</th>
          <td mat-cell *matCellDef="let element">
            <ng-container>{{
              element.headsets_count
              }}</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <button mat-mini-fab color="primary" class="m-r-10" (click)="issueHeadset(element)">
              <i class="fa fa-truck"></i>
            </button>
            <button mat-mini-fab color="primary" class="m-r-10" (click)="openEditDialog(element)">
              <i class="fa fa-pencil"></i>
            </button>
            <button mat-mini-fab color="primary" class="m-r-10" (click)="resendWelcome(element)">
              <i class="fa fa-envelope"></i>
            </button>
            <button mat-mini-fab color="accent" class="m-r-10" *ngIf="element.is_active" (click)="suspendUser(element)">
              <i class="fa fa-ban"></i>
            </button>
            <button mat-mini-fab color="accent" class="m-r-10" *ngIf="!element.is_active"
              (click)="suspendUser(element)">
              <i class="fa fa-undo"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 50]" (page)="pageChanged($event)" #userPag></mat-paginator>
    </div>
  </div>
</div>

<app-loader *ngIf="isLoading" [size]="'large'"></app-loader>